#root {
  font-family: "Roboto Mono", monospace;
  background-image: linear-gradient(to bottom right, #ece9ee, #dfb8fe);
}
body {
  overflow-x: hidden;
  width: 100vw;
}
a:link {
  text-decoration: none;
  color: #fd0de2;
}

a:visited {
  text-decoration: none;
  color: #fd0de2;
}
a:hover {
  color: #f71717;
}

a:active {
  text-decoration: none;
  color: #fd0de2;
}

.App {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appDiv {
  padding: 0;
  margin: 0;
}

/*transform the root to scale down to .6 when in landscape mode */

  /*make the root take up the whole screen */




/*NavBar*/

.navibar {
  margin-bottom: 2%;
  justify-content: space-between;
  width: 100vw;
  background-image: linear-gradient(to bottom right, #f2e6fb, #dfb8fe);
  outline-style: double;
  outline-color: #211c53;
}
.navibar-content {
  align-items: center;
  display: flex;
  height: 3rem;
  justify-content: space-around;
}
.leftlinks {
  display: flex;
  margin-left: 1.5em;
}
.iridiomlink {
  border: 2em;
  border-radius: 10%;
  width: 150px;
  text-align: center;
  font-size: 1.5em;
  font-weight: 600;
}
.info-button {
  background-color: #fd0de200;
  border-width: 0;
  color: #fd0de2;
}
.iridiom-description {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  font-weight: 700;
  color: #fd0de2;
  border-style: double;
  margin-top: -1em;
  margin-bottom: 1em;
  width: 20em;
}
.searchBar {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  font-weight: 700;
  color: #fd0de2;
  border-style: double;
  margin-top: 0em;
  margin-bottom: 1em;
  width: 20em;
}

/*Special Thanks Component*/

.specialThanksContainer {
  height: 100%;
  height: 50vw;
}
.specialHeader {
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
  margin-top: 2em;
}

.specialThanksPeople {
  display: flex;
  justify-content: center;
  align-items: center;
}
.specialThanksCell {
  cursor: pointer;
  width: 15em;
  height: 15em;
  padding: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 2em;
  border: 1px solid #333;
  border-radius: 20%;
  border-collapse: collapse;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  text-align: center;
  background-image: linear-gradient(rgb(243, 193, 232), rgb(243, 5, 239));
}
.special-thanks {
  margin-right: 1.5em;
}
.personName {
  display: flex;
}
.personAbbr {
  display: flex;
  flex-direction: column;
  font-size: 1em;
  margin: auto;
  margin-top: -0.3em;
}

.personElementSquare {
  display: flex;
  flex-direction: column;
  outline: blue;
  width: 3.5em;
  align-items: flex-start;
  border-style: groove;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 10px;
  border-width: 1px;
  height: 3.2em;
  background-color: #e9ecef;
}
.personNumber {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.8em;
}
.peopleContent {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  width: 90%;
  margin: 0 auto;
  align-items: stretch;
  flex-wrap: wrap;
  font-size: 0.9em;
}

/**/
.Categories {
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto Mono", monospace;
  margin-top: 3em;
}
.Equation {
  display: flex;
  align-items: center;
  margin-top: 2em;
  justify-content: center;
  margin-bottom: 2em;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

.appContainer {
  overflow-x: hidden;

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.categoryHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#idiomCategories {
  font-size: medium;
}
#clickCategoryText {
  font-size: small;
}

.categoryList {
  display: flex;
  flex-wrap: wrap;
  max-width: 50em;
  justify-content: center;
}
.categorySmall {
  border: 1px solid rgb(234, 154, 154);
  border-radius: 5px;
  margin: 0 1.5%;
  background-color: antiquewhite;
  font-family: "Roboto Mono", monospace;
  margin-bottom: 10px;
}

.inEquation {
  padding: 1% 0.7%;
  border: 1px solid rgb(234, 154, 154);
  border-radius: 50px;
  margin: 0 1.5%;
  animation-name: animategrow;
  animation-duration: 0.4s;
}

sub {
  pointer-events: none;
}

.inEquation:hover {
  background-color: rgb(234, 98, 98);
  transition: all 0.2s ease-in-out;
}
.categorySmall:hover {
  background-color: rgb(234, 98, 98);
  transition: all 0.2s ease-in-out;
}

button .inEquation {
  background-color: rgb(75, 27, 233);
}
/*ADD-ELEMENT */
#add-Idiom {
  display: flex;
  border: 1px solid rgb(234, 154, 154);
  border-radius: 50px;
  margin: 0 1.5%;
  align-items: center;
  flex-direction: column-reverse;
}

/* ELEMENTS IN TABLE*/

.arrowContainer{
display: flex;
flex-direction: row;
justify-content: center;
margin-top: 1%;
}

.pageNumber{
  padding-left: 1vw;
  padding-right: 1vw;
}

.arrow {
  border: solid black;
  border-width: 0 9px 9px 0;
  display: inline-block;
  padding: 3px;
  

}

.left:hover {
  transform: rotate(135deg) scale(2);
  transition: all 0.2s ease-in-out;
}

.right:hover{
  transform: rotate(-45deg) scale(2);
  transition: all 0.2s ease-in-out;

}

.right {
  transform: rotate(-45deg);
  /* -webkit-transform: rotate(-45deg); */
}

.left {
  transform: rotate(135deg);
  /* -webkit-transform: rotate(135deg); */
}

table {
  border: 2px solid rgba(116, 116, 116, 0.429);
  border-radius: 30px;
  width: 800px;
  height: 200px;
}

th {
  border-bottom: 1px solid black;
}

td {
  text-align: center;
}

.elNumber {
  text-align: left;
  padding-left: 0.2em;
}

.elName {
  text-align: center;
}

/*ELEMENT CATEGORIES*/
.elementCategory {
  cursor: pointer;
  width: 4em;
  height: 4em;
  padding: 1px;
  margin: 1px;
  border: 1px solid #333;
  border-radius: 20%;
  border-collapse: collapse;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  text-align: center;
}

.elementCategory.grow:hover {
  transform: scale(1.5);
}

/*individual elements*/
.elementCategory.Love {
  background-color: rgb(247, 160, 238);
}

.elementCategory.Love:hover {
  background-color: rgb(255, 70, 184);
}
.elementCategory.Books {
  background-image: linear-gradient(rgb(255, 153, 0), rgb(255, 179, 0));
}

.elementCategory.Body-parts {
  background-image: linear-gradient(rgb(221, 169, 90), rgb(231, 77, 77));
}
.elementCategory.Nature {
  background-image: linear-gradient(rgb(104, 226, 104), rgb(15, 120, 32));
}
.elementCategory.Food {
  background-color: rgb(255, 157, 0);
}
.elementCategory.Food:hover {
  background-color: rgb(212, 48, 12);
}

.elementCategory.Animals {
  background-color: rgb(127, 191, 255);
}
.elementCategory.Cats {
  background-color: #8b7868;
}
.elementCategory.Animals:hover {
  background-color: rgb(19, 138, 251);
}

.elementCategory.Clothing {
  background-color: rgb(42, 104, 108);
}

.elementCategory.Fruits {
  background-color: peachpuff;
}

.elementCategory.Money {
  background-color: rgb(244, 241, 89);
}

.elementCategory.Money:hover {
  background-color: rgb(244, 241, 29);
}
.elementCategory.Country {
  background-color: #596ef7;
}
.elementCategory.Advice {
  background-color: rgb(212, 233, 180);
}
.elementCategory.Advice:hover {
  background-color: rgb(86, 233, 51);
}
.elementCategory.Action {
  background-color: rgb(245, 189, 106);
}
.elementCategory.Action:hover {
  background-color: rgb(245, 189, 41);
}
.elementCategory.Website {
  background-image: linear-gradient(rgb(243, 193, 232), rgb(243, 5, 239));
}
.elementCategory.Website:hover {
  background-color: rgb(6, 153, 190);
}
.elementCategory.notSearched {
  background-color: rgba(136, 135, 135, 0.467);
}

/*table grid
*/
/* .row{
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 2px;
  background-color: rgb(245, 189, 106);
  border: 1px solid #333;
  border-collapse: collapse;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  width:100%;
} */
.notRow {
  cursor: pointer;
  width: 4em;
  height: 4em;
  padding: 1px;
  background-color: transparent;
  border: 1px transparent;
  border-radius: 40%;
  border-collapse: collapse;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  pointer-events: none;
}

.tableContainer {
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  
}

.tableGridContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.tableGridContainer {
  display: flex;
  grid-auto-flow: column;
  justify-content: flex-start;
  overflow-x: auto;
  margin: 0 auto;
  max-width: none;
  scrollbar-width: none;
}

.tableGrid {
  display: grid;
  grid-template-columns: repeat(18, 4em);
  justify-content: center;
  flex-wrap: nowrap;
}
@media (max-width: 767px) and (orientation: landscape) {
  .appContainer {
    max-width: 100vw;
    width: 100%;
    height: 100vh;
    /* transform: scale(0.6); */
  }
}

/* @media (max-width: 767px) and (orientation: landscape) {
  .tableGrid {
    transform: scale(0.6);
    margin-top: -17vw;
  }
} */

/* Rest of your existing styles... */


/*SearchResults
*/

.SearchResults {
  display: flex;
  grid-column: auto;
  background-color: antiquewhite;
  min-height: 15vw;
  flex-wrap: wrap;
  max-width: 1152px;
  margin: 0 auto;
  align-items: center;
  padding-left: 2em;
  padding-right: 2em;
}

.searchResultsTitle {
  margin-bottom: 1em;
  margin-top: 1em;
  margin-left: 1em;
  width: 100%;
}
.elementTag {
  cursor: pointer;
  width: 2em;
  height: 2em;
  padding: 2px;
  margin: 4px;
  border: 1px solid #333;
  border-radius: 20%;
  border-collapse: collapse;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  text-align: center;
  animation-name: animategrow;
  animation-duration: 0.4s;
}

.idiomkey {

  background-color: inherit;
  display: grid;
  margin: 1% auto;
  grid-template-columns: repeat(5, 8em);
  width: 100%;
  font-size: 0.8em;
  text-align: center;
  justify-items: center;
}

.idiomKeyContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  margin-top: 1em;
  justify-content: center;

}

/*MODALS*/

/* Intro Modal */

.welcomeText {
  text-align: center;
}

.idiomTitle {
  display: flex;
}
.idiomPhrase {
  margin-left: 10px;
  max-width: 70%;
}

.badgeTag {
  margin: 2px;
}
/*
.modal-header {
  padding: 0.5% 0.8%;
  background-color: #6b6d6b45;
  color: rgba(255, 255, 255, 0.766);
  display: grid;
  justify-items: center;
}
.cardAbbr {
  display: grid;
  justify-content: space-around;
}


.modal-footer {
  padding: 0.5% 0.8%;
  background-color: #6b6d6b45;
  color: white;
}

.tagSub {
  color: rgba(0, 0, 0, 0.723);
}
.cardImage {
  align-self: center;
}

.modal-content {
  background-color: #fefefeb9;
  border-radius: 10%;
  margin: auto;
  width: 90%;
  padding-top: 30%;
  padding-bottom: 16%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}*/

/*  border-radius: 10%;
  display:flex;
  position: fixed; 
  padding-top: 1vw;
  padding-bottom: 1vw;
  left: 40vw;
  top: 5vw;
  width: 20vw; 
  height: 26vw; 
  overflow: hidden; 
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.4);
  animation-name: animategrow;
  animation-duration: 0.4s;

  */
